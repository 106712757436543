import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from '../components/Layout'
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Helmet from 'react-helmet';
import Card from "react-bootstrap/Card"

import "/_custom.scss"

import * as classes from "../components/Site.module.scss"
import Favicon from "../images/favicon.ico";

const Portfolio = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;
  const images = data.allFile.nodes;

  return (
    <Layout>
      <Helmet>
        <title>Portfolio - Kevin Blank - kevinblank.com</title>
        <link rel="icon" type="image/x-icon" href={Favicon}></link>
      </Helmet>
      <main>
        <div >
        <Container>
          <Row>
            <Col>
              <h1 className={classes.subPageTitle}>Portfolio</h1>           
            </Col>
          </Row>
        </Container>
        <Container fluid="md">
          <Row>

          {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug;
          let postImage = {}

          for(const image of images){
            if(post.fileAbsolutePath.indexOf(image.dir) != -1 && image.relativePath.indexOf('primary') > 1){
              postImage = image;
              console.log(postImage);
            }
          }

          return (
            <Col xs={12}  md={4} lg={3}>
              <Card key={post.fields.slug}>
                <Link to={post.fields.slug} itemProp="url">
                <Card.Img variant="top" src={postImage.childImageSharp.original.src} style={{height:"200px",objectFit: "cover"}}/>
                </Link>
                <Card.Body>
                  <div style={{minHeight:"140px"}}>
                  <Card.Title><Link to={post.fields.slug} itemProp="url"  style={{textDecoration:"none"}}>
                          <span style={{textDecoration:"none"}}>{title}</span>
                        </Link>
                  </Card.Title>
                  <Card.Text>
                  <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                  </Card.Text>
                  </div>
                  <div>
                  <Link to="/portfolio" className="btn btn-primary btm-sm">View More</Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
        })}

          </Row>
        </Container>
        </div>
      </main>
    </Layout>
  )
}

export default Portfolio

// styles
const bigSpacing = {
  margin: "80px 100px",
}

const centerCenter = {
  position: "relative",
  left:" 50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  marginLeft: "6vw"
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
        fileAbsolutePath
      }
    }
    allFile(
      filter: {absolutePath: {regex: "/(portfolio)/"}, extension: {regex: "/(jpg)|(jpeg)|(png)/"}}
      sort: {fields: relativePath, order: DESC}
    ) {
      nodes {
        relativeDirectory
        childImageSharp {
          gatsbyImageData
          original {
            width
            height
            src
          }
        }
        relativePath
        absolutePath
        dir
      }
    }
  }
`